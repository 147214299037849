/* eslint-disable */
import React from 'react';

export default class Metaverse extends React.Component {
  componentDidMount () {
  }
  render () {
    return (
      <div>
      </div>
    )
  }
}